import type { JSX } from 'react';
import { Content } from '@prismicio/client';
import { PromoCarousel as PromoCarouselComponent } from '@/components';
import { SliceComponentProps } from '@prismicio/react';

/**
 * Props for `PromoCarousel`.
 */
export type PromoCarouselProps =
  SliceComponentProps<Content.PromoCarouselSlice>;

/**
 * Component for "PromoCarousel" Slices.
 */
const PromoCarousel = ({ slice }: PromoCarouselProps): JSX.Element => {
  return <PromoCarouselComponent slice={slice} />;
};

export default PromoCarousel;
